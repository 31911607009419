<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.status === 'success' ? '审核通过' : '驳回')" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="" prop="id" v-show="false">
        <a-input v-model="form.afterSaleId"/>
      </a-form-model-item>
      <a-form-model-item label="" prop="auditStatus" v-show="false">
        <a-input v-model="form.auditStatus"/>
      </a-form-model-item>
      <a-form-model-item :label="(this.form.status === 'success' ? '审核说明' : '驳回原因')" prop="failMessage" >
        <a-textarea v-model="form.failMessage" placeholder="请输入"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      status: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        voucherId: null,
        status: null,
        failMessage: ''
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        failMessage: [{
          required: true,
          message: '说明不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  created () {

  },
  computed: {},
  methods: {
    setForm (item) {
      this.form.voucherId = item.id
    },
    setStatus (status) {
      this.form.status = status
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        voucherId: null,
        failMessage: '',
        status: null
      }
    },
    // 提交保存.
    async determine () {
      console.log('提交数据：', JSON.stringify(this.form))
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'auditPayVoucher'
          const res = await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          console.log('res', res)
          if (res) {
            this.$store.dispatch('showToast', { message: '操作成功~' })
            this.isShow = false
            this.close()
            this.$emit('save')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
